














































import Vue from 'vue'
import VButton from '../components/VButton.vue'

export default Vue.extend({
  inheritAttrs: false,

  components: {
    VButton
  },

  props: {
    title: String,
    value: [String, Number],
    icon: String,
    subtitle: {
      type: String,
      default: 'Sold totale'
    },
    variant: {
      type: String,
      default: 'green',
      validator: value => /green|blue|red/.test(value)
    },
    currency: Boolean,
    number: Boolean
  },

  methods: {
    onLink() {
      this.$emit('link:click')
    }
  }
})
