




































import Vue from 'vue'

export default Vue.extend({
  props: {
    choosedMenuText: {
      type: String,
      required: true
    },
    menus: Array,
    buttonBackgroundColor: {
      type: String,
      default: 'primary'
    },
    buttonTextColor: {
      type: String,
      default: 'white'
    },
    iconColor: {
      type: String,
      default: 'white'
    }
  },

  data() {
    return {
      isShowDropDownMenu: false
    }
  }
})
