






































































































































import Vue, { VueConstructor } from 'vue'
import SfdStatCard from '@/components/SfdStatCard.vue'
import { GChart } from 'vue-google-charts'
import VDropdownButton from '@/components/VDropdownButton.vue'
import Organization from '../../../store/models/Organization'
import Logs from '../../../store/models/Logs'
import AccountMixin from '../../../mixins/accountMixin'

export default (Vue as VueConstructor<
  Vue & InstanceType<typeof AccountMixin>
>).extend({
  components: {
    SfdStatCard,
    GChart,
    VDropdownButton
  },

  data() {
    return {
      totalRefundBalance: 0,
      totalBalance: 0,
      totalInterestBalance: 0,
      chartData: [
        ['Month', 'Profit'],
        ['Jan', 200],
        ['Févr', 250],
        ['Mars', 250],
        ['Avr', 250],
        ['Mai', 250],
        ['Juin', 250],
        ['Juil', 300]
      ],
      chartOptions: {
        chart: {
          title: 'Company Performance',
          subtitle: 'Month : 2014-2017'
        }
      },
      columns: [
        {
          field: 'createdAt',
          label: 'Date',
          class: 'containerLog-title',
          align: 'left',
          date: true
        },
        {
          field: 'text',
          label: 'Information',
          class: 'containerLog-title',
          align: 'left'
        },
        {
          field: '_actions',
          label: 'Détail',
          class: 'containerLog-title',
          align: 'left'
        }
      ],

      menus: [],

      isShowDropDownMenu: false
    }
  },

  mixins: [AccountMixin],
  computed: {
    organization(): Organization {
      return Organization.loggedIn()
    },

    activities(): Logs[] {
      return Logs.query()
        .where('organizationId', this.organization.id)
        .all()
    }
  },

  mounted() {
    Logs.api().fetch()
    setTimeout(() => {
      this.totalBalance = this.soldeDecaissement
      this.totalRefundBalance = this.soldeRembourssement
      this.totalInterestBalance = this.soldeInterest
    }, 1000)
  },

  methods: {
    onSeeMore() {
      // TODO: Route to refund log details page
    }
  }
})
